import { Crop } from '@/types/crop';
import { Ratio } from '@/types/ratio';
import getSelectionSizes from 'components/SimpleCrop/utils/getSelectionSizes';
import { Orientation } from 'types/orientation';

const getDefaultImageCrop = ({
  width: imageWidth,
  height: imageHeight,
  ratio,
  orientation,
}: {
  width: number;
  height: number;
  ratio?: Ratio;
  orientation?: Orientation;
}): {
  crop: Crop;
  scale: number;
  orientation: Orientation;
} => {
  const {
    width: selectionWidth,
    height: selectionHeight,
    orientation: nextOrientation,
  } = getSelectionSizes({
    imageWidth,
    imageHeight,
    ratio,
    orientation,
  });

  // auto center crop default
  // const topPos = (imageHeight - selectionHeight) / 2;

  // top center crop default
  const topPos = 0;
  const leftPos = (imageWidth - selectionWidth) / 2;
  const cropCroppie = {
    x1: leftPos / imageWidth,
    y1: topPos / imageHeight,
    x2: (leftPos + selectionWidth) / imageWidth,
    y2: (topPos + selectionHeight) / imageHeight,
  };
  const scale =
    1 /
    (imageHeight >= imageWidth
      ? cropCroppie.x2 - cropCroppie.x1
      : cropCroppie.y2 - cropCroppie.y1);

  return {
    scale,
    crop: {
      x: cropCroppie.x1,
      y: cropCroppie.y1,
      width: Math.abs(cropCroppie.x2 - cropCroppie.x1),
      height: Math.abs(cropCroppie.y1 - cropCroppie.y2),
    },
    orientation: orientation || nextOrientation,
  };
};

export default getDefaultImageCrop;
