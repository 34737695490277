export enum CouponState {
  Success = 'success',
  Failed = 'failed',
}

export interface Coupon {
  id: string;
  description: string;
  discountedTotal: number;
  total: number;
}

export interface RecoveredCoupon {
  code: string;
  description: string;
}

export interface OrderCoupon {
  state: CouponState;
  value?: Coupon;
  error?: string;
  recoveredCoupons?: RecoveredCoupon[];
}

export interface Order {
  id: string;
  deviceId: string;
  status: OrderStatus;
  deliveryMethod: DeliveryMethod;
  storeId?: string;
  user?: OrderUser;
  coupon?: OrderCoupon;
  couponCode?: string;
  onlineDeposit?: OnlineDeposit;
  error?: string;
}

export type OrderUser = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
};

export type DeliveryMethod = 'PICKUP';
export type PaymentPlatform = 'STRIPE';

export enum OrderStatus {
  Start = 'Start',
  UploadingFiles = 'UploadingFiles',
  ReadyToSend = 'ReadyToSend',
  Sent = 'Sent',
  Failed = 'Failed',
  Paid = 'Paid',
}

export interface OnlineDeposit {
  minFee: number;
  percentageFee: number;
  paymentPlatform: PaymentPlatform;
  amount?: number;
}
