'use client';

import { useEffect } from 'react';

import { INTERVAL_TIME_LOGS, LOCAL_LOGS_KEY } from '@/constants';
import getLocalStorageItem from '@/utils/localStorage/getLocalStorageItem';
import setLocalStorageItem from '@/utils/localStorage/setLocalStorageItem';

import sendClientSideLogs from '../client';

const useLogs = (): void => {
  useEffect(() => {
    sendClientSideLogs(getLocalStorageItem(LOCAL_LOGS_KEY, []));
    setInterval(() => {
      const localLogs = getLocalStorageItem(LOCAL_LOGS_KEY, []);
      setLocalStorageItem(LOCAL_LOGS_KEY, []);

      sendClientSideLogs(localLogs).catch(() => {
        setLocalStorageItem(LOCAL_LOGS_KEY, localLogs);
      });
    }, INTERVAL_TIME_LOGS);
  }, []);
};

export default useLogs;
