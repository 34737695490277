'use client';

import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react';
import { cloneDeep } from 'lodash';

import { ACCEPTED_IMAGE_FORMAT } from '@/constants';
import handlePrepareImageError from '@/context/Uploader/utils/images/handlePrepareImageError';
import isImageStatusPreparing from '@/context/Uploader/utils/status/isImageStatusPreparing';
import isImageStatusUploading from '@/context/Uploader/utils/status/isImageStatusUploading';
import { addImage } from '@/context/Uploader/utils/uploaderIndexedDB';
import usePrevious from '@/hooks/usePrevious';
import { sendLog } from '@/services/logs';
import { Ratio } from '@/types/ratio';
import fileToDataUrl from '@/utils/image/fileToDataUrl';
import prepareImageFile from '@/utils/image/prepareImage';

import { UploaderImage, UploaderStatus } from './types/image';
import { UploaderReducerActionType } from './types/job';
import runImageCallbacksByStatus from './utils/callbacks/runImageCallbacksByStatus';
import runJobCallbacksByStatus from './utils/callbacks/runJobCallbacksByStatus';
import getDefaultImageCrop from './utils/images/getDefaultImageCrop';
import getImageWidthHeight from './utils/images/getImageWidthHeight';
import getImagesByStatus from './utils/job/getImageByStatus';
import getPreviousStatus from './utils/status/getPreviousStatus';
import isStatusAfter from './utils/status/isStatusAfter';
import isStatusBefore from './utils/status/isStatusBefore';
import {
  IMAGE_FILE_TYPE,
  NB_IMAGES_PREPARING_MAX,
  NB_IMAGES_UPLOADING_MAX,
} from './constants';
import jobsReducer from './reducer';
import {
  AddImagesToUploader,
  ReduceImageWorkerResponse,
  UploadImageWorkerResponse,
  WorkerResponseType,
} from './types';
import { UploaderContext, UploaderContextAction } from '.';

function UploaderProvider({ children }: PropsWithChildren) {
  const [jobs, dispatch] = useReducer(jobsReducer, []);

  const prepareImageWorkerRef = useRef<Worker>();
  const uploadImageWorkerRef = useRef<Worker>();

  // PREPARE IMAGE WORKER
  useEffect(() => {
    prepareImageWorkerRef.current = new Worker(
      new URL('./utils/workers/prepareImageWorker.ts', import.meta.url),
    );

    prepareImageWorkerRef.current.onmessage = (
      e: MessageEvent<ReduceImageWorkerResponse>,
    ) => {
      const { type } = e.data;

      if (type === WorkerResponseType.ERROR) {
        const { id, name, message } = e.data;

        handlePrepareImageError({ id, name, message, dispatch });
        return;
      }
      const { width, newFile, height, crop, blobUrl, id } = e.data;

      dispatch({
        type: UploaderReducerActionType.SetImageAsPrepared,
        payload: {
          id,
          newFile,
          crop,
          blobUrl,
          height,
          width,
        },
      });
    };

    return () => {
      prepareImageWorkerRef.current?.terminate();
    };
  }, []);

  // UPLOAD IMAGE WORKER
  useEffect(() => {
    uploadImageWorkerRef.current = new Worker(
      new URL('./utils/workers/uploadImageWorker.ts', import.meta.url),
    );
    if (uploadImageWorkerRef.current?.onmessage !== undefined) {
      uploadImageWorkerRef.current.onmessage = (
        e: MessageEvent<UploadImageWorkerResponse>,
      ) => {
        const { type } = e.data;

        if (type === WorkerResponseType.ERROR) {
          const { id, message } = e.data;
          sendLog('app_upload_file_state', {
            state: 'upload_failed',
            id,
            error: message,
          });
          dispatch({
            type: UploaderReducerActionType.SetImageAsFailed,
            payload: {
              id,
              error: new Error(message),
            },
          });
          return;
        }
        const { id, url, storageKey } = e.data;
        dispatch({
          type: UploaderReducerActionType.SetImageAsUploaded,
          payload: {
            id,
            url,
            storageKey,
          },
        });
      };
    }

    return () => {
      uploadImageWorkerRef.current?.terminate();
    };
  }, []);

  const previousJobs = usePrevious(cloneDeep(jobs));

  const prepareImage = useCallback(
    async ({
      image: imageToPrepare,
      dpi,
      ratio,
    }: {
      image: UploaderImage<UploaderStatus.Preparing>;
      dpi?: number;
      ratio?: Ratio;
    }) => {
      const image = imageToPrepare;

      // Resize image
      if (!imageToPrepare.skipQualityResize) {
        sendLog('app_upload_file_state', {
          state: 'preparing_resize_start',
          id: image.id,
          name: image.name,
        });

        // if (isHEIC(image.nativeFile)) {
        //   sendLog('app_upload_file_state', {
        //     state: 'conversion_heic_to_jpeg_start',
        //     id: image.id,
        //     name: image.name,
        //   });
        //   const heicFileConverted = await heicToJpeg(image.nativeFile);
        //   if (!heicFileConverted) {
        //     return;
        //   }
        //   sendLog('app_upload_file_state', {
        //     state: 'conversion_heic_to_jpeg_done',
        //     id: image.id,
        //     name: image.name,
        //   });
        //   image.nativeFile = heicFileConverted;
        // }

        // const exifData = await getExifData(fileToProcess.file);

        if (window.Worker && typeof OffscreenCanvas !== 'undefined') {
          prepareImageWorkerRef.current?.postMessage({
            file: image.nativeFile,
            ratio,
            targetDpi: dpi,
            id: image.id,
          });
        } else {
          try {
            const { id, newFile, width, height, crop, blobUrl } =
              await prepareImageFile({
                id: image.id,
                file: image.nativeFile,
                dpi,
                ratio,
              });
            dispatch({
              type: UploaderReducerActionType.SetImageAsPrepared,
              payload: {
                id,
                newFile,
                crop,
                blobUrl,
                height,
                width,
              },
            });
          } catch (error) {
            handlePrepareImageError({
              id: image.id,
              name: image.name,
              message: (error as Error)?.message,
              dispatch,
            });
          }
        }
      } else if (imageToPrepare.nativeFile.type !== IMAGE_FILE_TYPE) {
        sendLog('app_upload_file_state', {
          state: 'preparing_change_type_start',
          id: image.id,
          name: image.name,
        });

        if (window.Worker && typeof OffscreenCanvas !== 'undefined') {
          prepareImageWorkerRef.current?.postMessage({
            file: image.nativeFile,
            ratio,
            targetDpi: dpi,
            id: image.id,
            quality: 1,
          });
        } else {
          try {
            const { width, newFile, height, crop, blobUrl } =
              await prepareImageFile({
                id: image.id,
                file: image.nativeFile,
                dpi,
                ratio,
                quality: 1,
              });
            dispatch({
              type: UploaderReducerActionType.SetImageAsPrepared,
              payload: {
                id: image.id,
                newFile,
                blobUrl,
                height,
                width,
                crop,
              },
            });
          } catch (error) {
            handlePrepareImageError({
              id: image.id,
              name: image.name,
              message: (error as Error)?.message,
              dispatch,
            });
          }
        }
      } else {
        const file = image.nativeFile;
        const blobUrl = window.URL.createObjectURL(file);
        const { width: imageWidth, height: imageHeight } =
          await getImageWidthHeight(blobUrl);
        const { crop } = getDefaultImageCrop({
          width: imageWidth,
          height: imageHeight,
          ratio,
        });

        if (!file || !blobUrl) {
          handlePrepareImageError({
            id: image.id,
            name: image.name,
            message: 'No new file or blob url found',
            dispatch,
          });
          return;
        }

        try {
          const dataUrl = await fileToDataUrl(file);
          await addImage({ id: image.id, dataUrl });
          sendLog('app_upload_file_state', {
            state: 'preparing_done',
            id: image.id,
            name: image.name,
          });
          dispatch({
            type: UploaderReducerActionType.SetImageAsPrepared,
            payload: {
              id: image.id,
              blobUrl,
              height: imageHeight,
              width: imageWidth,
              crop,
            },
          });
        } catch (_) {
          dispatch({
            type: UploaderReducerActionType.SetImageAsPrepared,
            payload: {
              newFile: file,
              id: image.id,
              blobUrl,
              height: imageHeight,
              width: imageWidth,
              crop,
            },
          });
        }
      }
    },
    [],
  );

  const addImagesToUploader: AddImagesToUploader = useCallback(
    ({
      images,
      dpi,
      ratio,
      skipQualityResize = false,
      jobStatusChangedCallbacks = [],
      imageStatusChangedCallbacks = [],
    }) => {
      const acceptedImages = images.filter((file: File) => {
        if (ACCEPTED_IMAGE_FORMAT.includes(file.type)) {
          return true;
        }
        sendLog('app_upload_file_state', {
          state: 'invalid',
          type: file.type,
          name: file.name,
        });
        return false;
      });
      dispatch({
        type: UploaderReducerActionType.CreateJob,
        payload: {
          images: acceptedImages,
          jobStatusChangedCallbacks,
          imageStatusChangedCallbacks,
          dpi,
          ratio,
          skipQualityResize,
        },
      });
    },
    [],
  );

  useEffect(() => {
    jobs.forEach((job) => {
      const previousJob = previousJobs?.find(({ id }) => id === job.id);
      if (job.status !== previousJob?.status) {
        sendLog('app_upload_job_state', {
          state: job.status,
          id: job.id,
          imageCount: job.images.length,
        });
        // On some failed image cases, the job status is not following the "classic" status path
        // So we need to run the callbacks for each status between the previous status and the current status
        if (
          getPreviousStatus(job.status) !== previousJob?.status &&
          previousJob?.status &&
          job.status !== UploaderStatus.Failed
        ) {
          Object.values(UploaderStatus).forEach((status) => {
            if (
              status !== job.status &&
              isStatusAfter(
                status as UploaderStatus,
                previousJob?.status || UploaderStatus.Failed,
              ) &&
              isStatusBefore(status as UploaderStatus, job.status)
            ) {
              // loop on each status after the previous status
              runJobCallbacksByStatus(cloneDeep({ ...job, status }));
            }
          });
        }
        runJobCallbacksByStatus(job);
      }
      job.images.forEach((image) => {
        // If status changed
        if (
          image.status !==
          previousJobs
            ?.find(({ id }) => id === job.id)
            ?.images.find(({ id }) => id === image.id)?.status
        ) {
          sendLog('app_upload_file_state', {
            state: image.status,
            id: image.id,
            name: image.name,
          });
          if (isImageStatusPreparing(image)) {
            prepareImage({
              image: image as UploaderImage<UploaderStatus.Preparing>,
              dpi: job.dpi,
              ratio: job.ratio,
            });
          }
          if (isImageStatusUploading(image)) {
            uploadImageWorkerRef.current?.postMessage({
              id: image.id,
              file: image.newFile,
            });
          }
          runImageCallbacksByStatus(job, image);
        }
      });
    });

    // MANAGE PREPARING AND UPLOADING QUEUE
    const nbImagesInPreparing = getImagesByStatus({
      jobs,
      imageStatus: UploaderStatus.Preparing,
    }).length;

    const nbImagesInUploading = getImagesByStatus({
      jobs,
      imageStatus: UploaderStatus.Uploading,
    }).length;

    // If there is less than NB_IMAGES_PREPARING_MAX images in preparing we prepare the next images if there are some
    if (nbImagesInPreparing < NB_IMAGES_PREPARING_MAX) {
      getImagesByStatus({
        jobs,
        imageStatus: UploaderStatus.Added,
      })
        .slice(0, NB_IMAGES_PREPARING_MAX - nbImagesInPreparing)
        .forEach((imageToPrepare) => {
          dispatch({
            type: UploaderReducerActionType.SetImageAsPreparing,
            payload: {
              id: imageToPrepare.id,
            },
          });
        });
    }

    // If there is less than NB_IMAGES_UPLOADING_MAX images in uploading we upload the next images if there are some
    if (nbImagesInUploading < NB_IMAGES_UPLOADING_MAX) {
      getImagesByStatus({
        jobs,
        imageStatus: UploaderStatus.Prepared,
      })
        .slice(0, NB_IMAGES_UPLOADING_MAX - nbImagesInUploading)
        .forEach((imageToUpload) => {
          dispatch({
            type: UploaderReducerActionType.SetImageAsUploading,
            payload: {
              id: imageToUpload.id,
            },
          });
        });
    }
  }, [jobs, prepareImage, previousJobs]);

  const uploadedImagesCount = jobs.reduce(
    (totalUploadedImagesCount, job) =>
      totalUploadedImagesCount +
      job.images.filter(
        (image) =>
          image.status === UploaderStatus.Uploaded ||
          image.status === UploaderStatus.Failed ||
          image.previousStatuses.includes(UploaderStatus.Uploaded),
      ).length,
    0,
  );

  const allImagesCount = jobs.reduce(
    (totalImagesCount, job) => totalImagesCount + job.images.length,
    0,
  );

  const preparingJobsPreparedImagesCount = jobs.reduce(
    (jobTotalPreparedImagesCount, job) =>
      jobTotalPreparedImagesCount +
      (isStatusAfter(job.status, UploaderStatus.Preparing) ||
      job.status === UploaderStatus.Failed
        ? 0
        : job.images.filter(
            (image) =>
              image.status === UploaderStatus.Prepared ||
              image.status === UploaderStatus.Failed ||
              image.previousStatuses.includes(UploaderStatus.Prepared),
          ).length),
    0,
  );

  const preparingJobsImagesCount = jobs.reduce(
    (jobTotalPreparedImagesCount, job) =>
      jobTotalPreparedImagesCount +
      (isStatusAfter(job.status, UploaderStatus.Preparing) ||
      job.status === UploaderStatus.Failed
        ? 0
        : job.images.length),
    0,
  );

  const removeImageFromUploader = useCallback(
    (id: string) => {
      sendLog('app_upload_file_state', {
        state: 'removed_done',
        id,
      });
      dispatch({
        type: UploaderReducerActionType.RemoveImage,
        payload: {
          id,
        },
      });
    },
    [dispatch],
  );

  return (
    <UploaderContextAction.Provider
      value={useMemo(
        () => ({
          addImagesToUploader,
          removeImageFromUploader,
        }),
        [addImagesToUploader, removeImageFromUploader],
      )}
    >
      <UploaderContext.Provider
        value={useMemo(
          () => ({
            allImagesCount,
            uploadedImagesCount,
            preparingJobsImagesCount,
            preparingJobsPreparedImagesCount,
          }),
          [
            allImagesCount,
            uploadedImagesCount,
            preparingJobsImagesCount,
            preparingJobsPreparedImagesCount,
          ],
        )}
      >
        {children}
      </UploaderContext.Provider>
    </UploaderContextAction.Provider>
  );
}

export default UploaderProvider;
