'use client';

import { useEffect } from 'react';

import { sendLog } from '@/services/logs';
import useCartStore, {
  removeCorruptedCartItems,
} from '@/stores/Cart/CartStore';
import { setOrder } from '@/stores/Order/OrderStore';
import { OrderStatus } from '@/stores/Order/types';
import isCartFilesUploaded from '@/utils/cart/isCartFilesUploaded';

function CartSideEffects() {
  // Remove cart item if when image is not uploaded on page reload
  useEffect(() => {
    const uploadingFilesRemovedCount = removeCorruptedCartItems();
    if (uploadingFilesRemovedCount > 0) {
      sendLog('cart_uploading_files_removed', {
        count: uploadingFilesRemovedCount,
      });
    }
  }, []);

  // Set order status based on cart items
  const cartItems = useCartStore().items;
  useEffect(() => {
    if (!cartItems) return;
    if (cartItems.length === 0) {
      setOrder((draft) => {
        draft.status = OrderStatus.Start;
      });
    } else {
      const isAllFilesUploaded = isCartFilesUploaded(cartItems);

      if (isAllFilesUploaded) {
        sendLog('isAllFilesUploaded', {
          value: isAllFilesUploaded,
        });
      }

      setOrder((draft) => {
        draft.status = isAllFilesUploaded
          ? OrderStatus.ReadyToSend
          : OrderStatus.UploadingFiles;
      });
    }
  }, [cartItems]);

  return null;
}

export default CartSideEffects;
