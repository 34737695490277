import getImageOrientation from '@/context/Uploader/utils/images/getImageOrientation';
import { Crop } from '@/types/crop';
import { Ratio } from '@/types/ratio';
import { Orientation } from 'types/orientation';
import round from 'utils/number/round';

interface Props {
  imageWidth: number;
  imageHeight: number;
  ratio?: Ratio;
  crop?: Crop;
  orientation?: Orientation;
}

const getSelectionSizes = ({
  imageWidth,
  imageHeight,
  ratio,
  crop,
  orientation,
}: Props): {
  height: number;
  width: number;
  orientation: Orientation;
} => {
  if (!ratio) {
    return {
      height: imageHeight,
      width: imageWidth,
      orientation: getImageOrientation(imageWidth, imageHeight, crop),
    };
  }

  let selectionWidth: number;
  let selectionHeight: number;
  let nextOrientation: Orientation;

  //  _______________
  //  |   Image     |
  //  ---------------
  if (
    (orientation && orientation === 'landscape') ||
    (!orientation &&
      getImageOrientation(imageWidth, imageHeight, crop, ratio) === 'landscape')
  ) {
    selectionWidth = imageWidth;
    selectionHeight = (ratio.min * imageWidth) / ratio.max;
    nextOrientation = 'landscape';

    while (selectionHeight > imageHeight) {
      selectionWidth -= 0.1;
      selectionHeight = (ratio.min * selectionWidth) / ratio.max;
    }
  }
  //  _________
  //  |       |
  //  |       |
  //  | Image |
  //  |       |
  //  |       |
  //  ---------
  else {
    selectionHeight = imageHeight;
    selectionWidth = (ratio.min * imageHeight) / ratio.max;
    nextOrientation = 'portrait';

    while (selectionWidth > imageWidth) {
      selectionHeight -= 0.1;
      selectionWidth = (ratio.min * selectionHeight) / ratio.max;
    }
  }

  return {
    height: round(selectionHeight),
    width: round(selectionWidth),
    orientation: nextOrientation,
  };
};

export default getSelectionSizes;
