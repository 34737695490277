'use client';

import { useEffect } from 'react';
import Cookies from 'js-cookie';
import SmartlookClient from 'smartlook-client';

import { AB_SMARTLOOK, COOKIE_DEVICE_ID } from '@/constants';
import { hasFeature } from '@/services/abFeatures/client';
import { sendLog } from '@/services/logs';

function Smartlook() {
  useEffect(() => {
    (async () => {
      const isSmartlookEnabled = await hasFeature(AB_SMARTLOOK);
      const deviceId = Cookies.get(COOKIE_DEVICE_ID);
      if (
        isSmartlookEnabled &&
        process.env.NEXT_PUBLIC_SMARTLOOK_API_KEY &&
        deviceId
      ) {
        sendLog('ui_smartlook_enabled', {
          deviceId,
        });
        SmartlookClient.init(process.env.NEXT_PUBLIC_SMARTLOOK_API_KEY);
        SmartlookClient.identify(deviceId, {});
        SmartlookClient.record({
          forms: true,
          emails: true,
          numbers: true,
        });
      }
    })();
  }, []);

  return null;
}

export default Smartlook;
